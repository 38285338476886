
html{
    --primary-color:#FF8D00;
    font-size: 80px;
    @media (max-width: 750px) {
        font-size: 13.33vw;
    }
    @media (min-width: 1000px) {
        font-size:90px;
    }
    @media (min-width: 1680px) {
        font-size: 100px;
    }
    @media(min-width:2100px){
        font-size:110px;
    }
}

.lh164{
    line-height: 1.64rem;
}

@for $i from 1 through 140 {
    .pl#{$i}{ padding-left:#{$i/100}rem; }
    .w#{$i}{ width:#{$i/100}rem; }
    .h#{$i}{ height:#{$i/100}rem; }
    .pr#{$i}{ padding-right:#{$i/100}rem; }
    .pt#{$i}{ padding-top:#{$i/100}rem; }
    .pb#{$i}{ padding-bottom:#{$i/100}rem; }
    .ml#{$i}{ margin-left:#{$i/100}rem; }
    .mt#{$i}{ margin-top:#{$i/100}rem; }
    .mr#{$i}{ margin-right:#{$i/100}rem; }
    .mb#{$i}{ margin-bottom:#{$i/100}rem; }
    .fz#{$i}{ font-size:#{$i/100}rem; }
    .lh#{$i}{ line-height:#{$i/100}rem; }
    .rad#{$i}{ border-radius:#{$i/100}rem; }
    .top#{$i}{ top:#{$i/100}rem; }
    .bottom#{$i}{ bottom:#{$i/100}rem; }

}
.opa7{
    opacity: 0.7;
}
.pad{
    padding:0 2.6%;
}


.pad-lg{
    padding-left:8%;
    padding-right:8%;
}

.w253{
    width:2.53rem;
}
.ccc{
    color:#ccc;
}

.primaryText{
    color:var(--primary-color)
}

.hover-primary{
    &:hover{
        color:var(--primary-color)
    }
}

.fw400{
    font-weight: 400;
}
.fw500{
    font-weight: 500;
}

.fw400{
    font-weight: 600;
}

.fw800,.bold,.fw600{
    font-weight: 800;
}
.block-center {
	margin-left: auto;
	margin-right: auto;
}

.cursor {
	cursor: pointer;
}

.full-width {
	width: 100%;
}

.window-height {
	height: 100vh;
}

.window-width {
	width:100%;
}

.relative {
	position: relative;
}

.absolute{
    position:absolute;
}

.oh {
	overflow: hidden;
}

.ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.tac {
	text-align: center;
}

.sizing {
	box-sizing: border-box;
}

.text-white{
    color:#fff;
}
.bg-white {
	background: #fff;
}

.text-black{
    color:#000;
}

.text-black2{
    color:#212121;
}

.text-grey{
    color:#888;
}

.tar{
    text-align: right;
}

.wrap{
    p{
        margin-top:0.2rem;
        span{
           display: block;
        }
    }
}


.red{
    color:red;
    display: inline-block !important;
}

.grey{
    color:#999;
}


.appName{
    @media(max-width:500px){
        font-size:1.1rem;
    }
}
